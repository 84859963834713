import Appsignal from "@appsignal/javascript";
import { plugin as pluginNetwork } from "@appsignal/plugin-breadcrumbs-network";
import { plugin as pluginPath } from "@appsignal/plugin-path-decorator";
import { plugin as pluginConsole } from "@appsignal/plugin-breadcrumbs-console";
import { plugin as pluginWindowEvents } from "@appsignal/plugin-window-events";

const getReleaseStage = () => {
  switch (location.hostname) {
    case "staging.laufbahndiagnostik.ch":
      return "staging";
    case "laufbahndiagnostik.ch":
      return "production";
    default:
      return "development";
  }
};

export const appsignal = new Appsignal({
  key:
    // These keys are not secrets, as they have to end up on the frontend anyway.
    getReleaseStage() === "production"
      ? "00810172-9a43-4329-a76e-9192fb15d2a8"
      : getReleaseStage() === "staging"
        ? "bf4948da-9883-4cc3-88d3-2709a8a16183"
        : "fccc98c8-4d4c-4d17-a59c-d078fc6ab6c9",

  revision: import.meta.env.VITE_APP_REVISION,
});

appsignal.use(pluginNetwork({ fetchEnabled: true, xhrEnabled: true }));
appsignal.use(pluginPath({}));
appsignal.use(pluginConsole({}));
appsignal.use(pluginWindowEvents({}));

console.log("APP_REVISION", import.meta.env.VITE_APP_REVISION);
